import { User } from "./types";

export enum ActionType {
	LOGIN,
	LOGOUT,
}

export interface LOGIN {
	type: ActionType.LOGIN;
	payload: User;
}

export interface LOGOUT {
	type: ActionType.LOGOUT;
}

export type Actions = LOGIN | LOGOUT;
