import React, { useState } from "react";
import "../../styles/pages/register/register.css";
import ask_demo_img from "../../Assets/images/ask_demo_img.png";
import axios from "axios";
import PageLayout from "../../components/base/PageLayout";
import { ALL_PAGES_PADDING_X } from "../MainIntro";

export default function Inquiries() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [shopname, setShopname] = useState("");
  const [url, setUrl] = useState("");
  const [number, setNumber] = useState("");
  const [extra, setExtra] = useState("");

  function submitForm(e: any) {
    if (!shopname || !name || !number || !email) {
      alert("필수 항목은 입력해 주세요.");
    } else {
      axios.post(
        process.env.REACT_APP_SERVER_BASE_PATH + "landing/askdemo-submit/",
        {
          shopname,
          url,
          name,
          number,
          email,
          extra,
        },
      );
      (window as any).dataLayer.push({
        event: "Inquiry_completed",
      });
      resetFormAndShowConfirmation(e);
    }
    //  console.log(process.env.REACT_APP_SERVER_BASE_PATH);
  }

  function resetFormAndShowConfirmation(e: any) {
    setEmail("");
    setName("");
    setShopname("");
    setUrl("");
    setNumber("");
    setExtra("");
    alert("감사합니다, 인센토 팀이 곧 연락드리겠습니다.");
  }

  return (
    <PageLayout>
      <div
        className={`intro-container flex flex-col justify-start gap-[10%] bg-[#E8F3FF] p-10 md:flex-row md:justify-center md:py-[136px] px-[${ALL_PAGES_PADDING_X}] md:px-[15%]`}
      >
        <div className="text-container relative z-10 w-full max-w-[550px] pt-[60px]">
          <div className="flex w-full flex-col items-start">
            <div className="mb-[16px] w-full text-[36px] font-semibold leading-[1.3]">
              문의하기
            </div>
            <div className="inputform-container w-full">
              <div className="mt-3 flex w-full flex-col items-start justify-center">
                <div className="mb-1 text-[14px] font-semibold text-[#363636]">
                  자사몰 이름 <span className="text-base text-red-400">*</span>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={shopname}
                    onChange={(e) => {
                      setShopname(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder="자사몰 이름을 입력해 주세요."
                    className="w-full border-x-transparent border-t-transparent border-b-[#636363] bg-[#E8F3FF] text-sm font-normal text-[#363636]"
                  />
                </div>
              </div>
              <div className="mt-3 flex w-full flex-col items-start justify-center">
                <div className="mb-1 text-[14px] font-semibold text-[#363636]">
                  웹사이트 URL
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder="웹사이트 url을 입력해 주세요. (선택사항)"
                    className="w-full border-x-transparent border-t-transparent border-b-[#636363] bg-[#E8F3FF] text-sm font-normal text-[#363636]"
                  />
                </div>
              </div>
              <div className="mt-3 flex w-full flex-col items-start justify-center">
                <div className="mb-1 text-[14px] font-semibold text-[#363636]">
                  성함 <span className="text-base text-red-400">*</span>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder="성함을 입력 해 주세요."
                    className="w-full border-x-transparent border-t-transparent border-b-[#636363] bg-[#E8F3FF] text-sm font-normal text-[#363636]"
                  />
                </div>
              </div>
              <div className="mt-3 flex w-full flex-col items-start justify-center">
                <div className="mb-1 text-[14px] font-semibold text-[#363636]">
                  휴대폰 번호 <span className="text-base text-red-400">*</span>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={number}
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder="휴대폰 번호를 입력해주세요 (숫자만)"
                    className="w-full border-x-transparent border-t-transparent border-b-[#636363] bg-[#E8F3FF] text-sm font-normal text-[#363636]"
                  />
                </div>
              </div>
              <div className="mt-3 flex w-full flex-col items-start justify-center">
                <div className="mb-1 text-[14px] font-semibold text-[#363636]">
                  이메일 <span className="text-base text-red-400">*</span>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    name="name"
                    id="name"
                    placeholder="이메일을 입력해주세요"
                    className="w-full border-x-transparent border-t-transparent border-b-[#636363] bg-[#E8F3FF] text-sm font-normal text-[#363636]"
                  />
                </div>
              </div>
              <div className="mt-3 flex w-full flex-col items-start justify-center">
                <div className="mb-3 text-[14px] font-semibold text-[#363636]">
                  요청사항
                </div>
                <div className="relative w-full">
                  <textarea
                    value={extra}
                    onChange={(e) => {
                      setExtra(e.target.value);
                    }}
                    name="name"
                    id="name"
                    rows={5}
                    placeholder="연락 받으실 때 요청 사항이 있으시다면 입력해 주세요. (선택사항)"
                    className="h-30 w-full border border-solid border-[#636363] bg-[#E8F3FF] px-3 py-2 text-sm font-normal text-gray-800 transition ease-in-out focus:outline-none"
                  />
                </div>
              </div>
              <button
                onClick={submitForm}
                name="name"
                id="name"
                className="mt-[30px] h-[54px] w-[163px] rounded-md border border-transparent bg-[#235BF5] px-6 py-3 text-white duration-500 hover:border-[#235BF5] hover:font-semibold"
              >
                신청하기
              </button>
            </div>
          </div>
          <div className="flex w-full gap-[8px] pt-6"></div>
        </div>
        <div className="img-container relative hidden items-center overflow-hidden md:flex md:max-w-[500px]">
          <img
            src={ask_demo_img}
            alt="hero_img_dashboard"
            className="relative z-0"
          />
        </div>
      </div>
    </PageLayout>
  );
}
