import React, { useEffect } from "react";
import "../../styles/components/home/navbar/navbar.css";
import logo from "../../Assets/images/incento_cropped.png";
import { Link } from "react-router-dom";
import burger from "../../Assets/images/menu-4-line (1).svg";

export default function LoginNavbar() {
  const [show, setShow] = React.useState(false);
  const [navHide, setNavHide] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    let yScroll = window.scrollY;
    setShow(yScroll > 0);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const elem = e.target as HTMLElement;
      if (
        !elem?.classList.contains("nav_burger") &&
        !elem?.classList.contains("mini__nav")
      ) {
        setNavHide(true);
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <section
      className={
        show
          ? !navHide
            ? "navbar__container show_nav hidenav"
            : "navbar__container show_nav"
          : "navbar__container"
      }
    >
      <div className="nav__main">
        <img
          src={burger}
          onClick={() => {
            setNavHide(!navHide);
          }}
          alt="nav_burger"
          className="nav_burger"
        />
        <div className="nav_left">
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <div className="nav_logo">
              <img src={logo} alt="" />
              <strong className="nav_logo_name">incento</strong>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}
