import Slider from "react-infinite-logo-slider";

// gotta fix div tags 겹치게
export default function LogoSlide() {
  return (
    <div
      className={`intro-container flex h-[102px] w-screen items-center justify-center gap-10 bg-white p-[10px] md:flex-row`}
    >
      <div className="w-screen">
        <Slider
          width="130px"
          duration={70}
          pauseOnHover={true}
          blurBorders={false}
          blurBorderColor={"#fff"}
        >
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo2.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo3.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo4.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo5.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo6.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo7.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo8.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo9.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo10.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo11.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo12.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo13.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo14.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo15.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo16.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="mr-[45px] flex h-[75%] w-[75%] items-center justify-center">
              <img
                src="/images/intro/logos/hero_img_logo17.svg"
                alt="any"
                className="w-36"
              />
            </div>
          </Slider.Slide>
        </Slider>
      </div>
    </div>
  );
}
