import { State } from "./types";

// refer to types.tsx
const DEFAULT_STATE: State = {
	user: null,
};

// If we already saved the data in the localStorage and it is still available, then retrieve it
const localStorageData: string | null = localStorage.getItem("state");
const parsedState = localStorageData ? JSON.parse(localStorageData) : null;

const INITIAL_STATE: State = {
	user: parsedState?.user || DEFAULT_STATE.user,
};

export default INITIAL_STATE;
