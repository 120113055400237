import { ALL_PAGES_PADDING_X } from "../../pages/MainIntro";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

export default function Intro() {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const [ref2, inView2] = useInView({ threshold: 0.1 });
  const [ref3, inView3] = useInView({ threshold: 0.1 });
  const [ref4, inView4] = useInView({ threshold: 0.1 });
  const [ref5, inView5] = useInView({ threshold: 0.1 });

  return (
    <div
      className={`intro-container flex flex-col justify-start gap-10 bg-[#E8F3FF] p-10 md:flex-row md:justify-center md:py-[12%] px-[${ALL_PAGES_PADDING_X}] md:px-[15%]`}
    >
      <div className="text-container relative z-10 pt-[60px]">
        <div className="mb-[16px] flex gap-2">
          <div>
            <span className="h-[50px] text-[16px] font-semibold text-[#235BF5]">
              카페24
            </span>
            <span className="h-[50px] text-[16px] font-semibold">평점</span>
          </div>
          <style>
            {`
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `}
          </style>

          <div className="flex h-[20px]">
            <div ref={ref}>
              {inView && (
                <img
                  src="/images/intro/hero_카페24star.svg"
                  alt="star"
                  style={{
                    animation: `fadeIn 0.5s ease forwards`,
                  }}
                  className="h-[20px] w-[20px]"
                />
              )}
            </div>
            <div ref={ref2}>
              {inView2 && (
                <img
                  src="/images/intro/hero_카페24star.svg"
                  alt="star"
                  style={{
                    animation: `fadeIn 1.0s ease forwards`,
                  }}
                  className="h-[20px] w-[20px]"
                />
              )}
            </div>

            <div ref={ref3}>
              {inView3 && (
                <img
                  src="/images/intro/hero_카페24star.svg"
                  alt="star"
                  style={{
                    animation: `fadeIn 1.5s ease forwards`,
                  }}
                  className="h-[20px] w-[20px]"
                />
              )}
            </div>

            <div ref={ref4}>
              {inView4 && (
                <img
                  src="/images/intro/hero_카페24star.svg"
                  alt="star"
                  style={{
                    animation: `fadeIn 2.0s ease forwards`,
                  }}
                  className="h-[20px] w-[20px]"
                />
              )}
            </div>

            <div ref={ref5}>
              {inView5 && (
                <img
                  src="/images/intro/hero_카페24star.svg"
                  alt="star"
                  style={{
                    animation: `fadeIn 2.5s ease forwards`,
                  }}
                  className="h-[20px] w-[20px]"
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col items-start">
          <div className="mb-[16px] text-[36px] font-semibold leading-[1.3]">
            리퍼럴 마케팅으로 <br className="hidden md:inline" />
            구매력 높은 고객을 획득하세요!
          </div>
          <div className="text-[18px] leading-[1.5] text-[#636363] md:mb-[48px]">
            리퍼럴 마케팅은 <br className="inline md:hidden" />
            신뢰를 기반으로 이루어지기 때문에
            <br />
            구매 전환율이 4배 더 높고, <br className="inline md:hidden" />
            리퍼럴로 가입한 고객은 <br />
            Life Time Value가 20% 더 높습니다
          </div>
        </div>
        <div className="flex w-full gap-[8px] pt-6">
          <a href="https://dashboard.incento.kr/login/">
            <button className="h-[54px] w-[163px] rounded-md border border-transparent bg-[#235BF5] px-6 py-3 text-white duration-500 hover:border-[#235BF5] hover:font-semibold">
              시작하기
            </button>
          </a>
          <Link to="/inquiries">
            <button className="h-[54px] w-[163px] rounded-md bg-white px-6 py-3 text-[#222222] duration-500 hover:bg-black hover:font-semibold hover:text-white">
              문의하기
            </button>
          </Link>
        </div>
      </div>
      <div className="img-container relative flex items-center overflow-hidden pt-[35px] md:max-w-[600px] md:pt-[80px]">
        <img
          src="/images/intro/hero_img_dashboard.svg"
          alt="hero_img_dashboard"
          className="relative z-0"
        />
      </div>
    </div>
  );
}
