import React from "react";
import "../../styles/pages/terms/terms.css";
import NavBar from "../../components/base/NavBar";
import Footer from "../../components/base/Footer";
import termsScript from "../../scripts/terms/terms.json";
import sellerScript from "../../scripts/terms/seller.json";

export default function Terms() {
  // useEffect(() => {
  //     window.scrollTo({
  //       top: 0,
  //       left: 0
  //     });
  //   }, []);
  return (
    <>
      <div className="mx-auto w-screen pb-5 font-sans">
        <NavBar />
      </div>
      <div className="bg-[#f3fafd]">
        <div className="flex">
          <div className="terms__container">
            <p className="terms__title">{termsScript.title}</p>
            <p className="terms__subtitle">{termsScript.description}</p>
            <div className="terms__section">
              <p>{"1. " + termsScript.content[0].title}</p>
              <div className="terms__subsection">
                {termsScript.content[0].content.map((text, i) => (
                  <div className="terms__subsection_row">
                    <p>{i + 1 + "."}</p>
                    <p>{text.title}</p>
                  </div>
                ))}
              </div>
              <p>{"2. " + termsScript.content[1].title}</p>
              <p>{"2-1. " + termsScript.content[1].content[0].title}</p>
              <p>
                {"description" in termsScript.content[1].content[0]
                  ? termsScript.content[1].content[0].description
                  : ""}
              </p>
              <p>{"2-2. " + termsScript.content[1].content[1].title}</p>
              <p>
                {"description" in termsScript.content[1].content[1]
                  ? termsScript.content[1].content[1].description
                  : ""}
              </p>
              <p>{"2-3. " + termsScript.content[1].content[2].title}</p>
              <p>
                {"description" in termsScript.content[1].content[2]
                  ? termsScript.content[1].content[2].description
                  : ""}
              </p>
              <p>{"2-4. " + termsScript.content[1].content[3].title}</p>
              <p>
                {"description" in termsScript.content[1].content[3]
                  ? termsScript.content[1].content[3].description
                  : ""}
              </p>
              <p>{"2-5. " + termsScript.content[1].content[4].title}</p>
              <p>
                {"description" in termsScript.content[1].content[4]
                  ? termsScript.content[1].content[4].description
                  : ""}
              </p>
              <p>{"2-6. " + termsScript.content[1].content[5].title}</p>
              <p>
                {"description" in termsScript.content[1].content[5]
                  ? termsScript.content[1].content[5].description
                  : ""}
              </p>
              <div className="terms__subsection">
                {"disco" in termsScript.content[1].content[5] &&
                termsScript.content[1].content[5].disco
                  ? termsScript.content[1].content[5].disco.map((text, i) => (
                      <div className="terms__subsection_row">
                        <p>{i + 1 + "."}</p>
                        <p>{text.title}</p>
                      </div>
                    ))
                  : ""}
              </div>
              <p>
                {"subtitle" in termsScript.content[1].content[5]
                  ? termsScript.content[1].content[5].subtitle
                  : ""}
              </p>
              <p>{"2-7. " + termsScript.content[1].content[6].title}</p>
              <p>
                {"description" in termsScript.content[1].content[6]
                  ? termsScript.content[1].content[6].description
                  : ""}
              </p>
              <p>{"2-8. " + termsScript.content[1].content[7].title}</p>
              <p>
                {"description" in termsScript.content[1].content[7]
                  ? termsScript.content[1].content[7].description
                  : ""}
              </p>
              <p>{"2-9. " + termsScript.content[1].content[8].title}</p>
              <p>
                {"description" in termsScript.content[1].content[8]
                  ? termsScript.content[1].content[8].description
                  : ""}
              </p>
              <p>{"2-10. " + termsScript.content[1].content[9].title}</p>
              <p>
                {"description" in termsScript.content[1].content[9]
                  ? termsScript.content[1].content[9].description
                  : ""}
              </p>
              <div className="terms__subsection">
                {"disco" in termsScript.content[1].content[9] &&
                termsScript.content[1].content[9].disco
                  ? termsScript.content[1].content[9].disco.map((text, i) => (
                      <ul className="terms__subsection_row">
                        <li>{text.title}</li>
                      </ul>
                    ))
                  : ""}
              </div>
              <p>{"3. " + termsScript.content[2].title}</p>
              <p>{"3-1. " + termsScript.content[2].content[0].title}</p>
              <p>
                {"description" in termsScript.content[2].content[0]
                  ? termsScript.content[2].content[0].description
                  : ""}
              </p>
              <p>{"3-2. " + termsScript.content[2].content[1].title}</p>
              <p>
                {"description" in termsScript.content[2].content[1]
                  ? termsScript.content[2].content[1].description
                  : ""}
              </p>
              <p>{"3-3. " + termsScript.content[2].content[2].title}</p>
              <p>
                {"description" in termsScript.content[3].content[2]
                  ? termsScript.content[3].content[2].description
                  : ""}
              </p>
              <p>{"3-4. " + termsScript.content[2].content[3].title}</p>
              <p>
                {"description" in termsScript.content[2].content[3]
                  ? termsScript.content[2].content[3].description
                  : ""}
              </p>
              {"subtitle" in termsScript.content[2].content[3]
                ? termsScript.content[2].content[3].subtitle
                : ""}
              <div className="ml-5 -mt-4">
                {"subtitle2" in termsScript.content[2].content[3]
                  ? termsScript.content[2].content[3].subtitle2
                  : ""}
              </div>
              <div className="ml-5 -mt-5">
                <ul>
                  <li>
                    <span className="p-3 text-2xl"> &#x2022; </span>
                    {"subtitle3" in termsScript.content[2].content[3]
                      ? termsScript.content[2].content[3].subtitle3
                      : ""}
                  </li>
                </ul>
              </div>
              <div className="ml-5">
                {"subtitle4" in termsScript.content[2].content[3]
                  ? termsScript.content[2].content[3].subtitle4
                  : ""}
                <ul>
                  <li>
                    <span className="p-3 text-2xl"> &#x2022; </span>
                    {"subtitle5" in termsScript.content[2].content[3]
                      ? termsScript.content[2].content[3].subtitle5
                      : ""}
                  </li>
                  <li>
                    <span className="p-3 text-2xl"> &#x2022; </span>
                    {"subtitle5" in termsScript.content[2].content[3]
                      ? termsScript.content[2].content[3].subtitle6
                      : ""}
                  </li>
                </ul>
              </div>
              <p>{"4. " + termsScript.content[3].title}</p>
              <p>{"4-1. " + termsScript.content[3].content[0].title}</p>
              <p>
                {"description" in termsScript.content[3].content[0]
                  ? termsScript.content[3].content[0].description
                  : ""}
              </p>
              <p>{"4-2. " + termsScript.content[3].content[1].title}</p>
              <p>
                {"description" in termsScript.content[3].content[1]
                  ? termsScript.content[3].content[1].description
                  : ""}
              </p>
              <p>{"4-3. " + termsScript.content[3].content[2].title}</p>
              <p>
                {"description" in termsScript.content[3].content[2]
                  ? termsScript.content[3].content[2].description
                  : ""}
              </p>
              <p>{"5. " + termsScript.content[4].title}</p>
              <p>{"5-1. " + termsScript.content[4].content[0].title}</p>
              <p>
                {"description" in termsScript.content[4].content[0]
                  ? termsScript.content[4].content[0].description
                  : ""}
              </p>
              <div className="terms__subsection">
                {"disco" in termsScript.content[4].content[0] &&
                termsScript.content[4].content[0].disco
                  ? termsScript.content[4].content[0].disco.map((text, i) => (
                      <div className="terms__subsection_row">
                        <p>{i + 1 + "."}</p>
                        <p>{text.title}</p>
                      </div>
                    ))
                  : ""}
              </div>
              <p>{"5-2. " + termsScript.content[4].content[1].title}</p>
              <p>
                {"description" in termsScript.content[4].content[1]
                  ? termsScript.content[4].content[1].description
                  : ""}
              </p>
              <p>{"5-3. " + termsScript.content[4].content[2].title}</p>
              <p>
                {"description" in termsScript.content[4].content[2]
                  ? termsScript.content[4].content[2].description
                  : ""}
              </p>
              <div className="terms__subsection">
                {"disco" in termsScript.content[4].content[2] &&
                termsScript.content[4].content[2].disco
                  ? termsScript.content[4].content[2].disco.map((text, i) => (
                      <div className="terms__subsection_row">
                        <p>{i + 1 + "."}</p>
                        <p>{text.title}</p>
                      </div>
                    ))
                  : ""}
              </div>
              <p>{"5-4. " + termsScript.content[4].content[3].title}</p>
              <p>
                {"description" in termsScript.content[4].content[3]
                  ? termsScript.content[4].content[3].description
                  : ""}
              </p>
              <p>{"5-5. " + termsScript.content[4].content[4].title}</p>
              <p>
                {"description" in termsScript.content[4].content[4]
                  ? termsScript.content[4].content[4].description
                  : ""}
              </p>
              <p>{"5-6. " + termsScript.content[4].content[5].title}</p>
              <p>
                {"description" in termsScript.content[4].content[5]
                  ? termsScript.content[4].content[5].description
                  : ""}
              </p>
              <p>{"공지일자: " + termsScript.report_date}</p>
              <p>{"적용일자: " + termsScript.start_date}</p>
            </div>

            <p className="terms__title">{sellerScript.title}</p>
            <div className="terms__section">
              <p>{"1. " + sellerScript.subtitle_one}</p>
              <div className="terms__subsection">
                {sellerScript.privacy.map((text, i) => (
                  <ul className="terms__subsection_row">
                    <li>{text.title}</li>
                  </ul>
                ))}
              </div>
              <p>{"2. " + sellerScript.subtitle_two}</p>
              <div className="terms__subsection">
                {sellerScript.consignment.map((text, i) => (
                  <ul className="terms__subsection_row">
                    <li>{text.title}</li>
                  </ul>
                ))}
              </div>
              <hr
                style={{
                  background: "gray",
                  color: "gray",
                  borderColor: "gray",
                  height: "3px",
                  width: "100%",
                }}
              />
              <p>{sellerScript.consignment_details}</p>
              <hr
                style={{
                  background: "gray",
                  color: "gray",
                  borderColor: "gray",
                  height: "3px",
                  width: "100%",
                }}
              />
              <p>{sellerScript.plugin_title}</p>
              <div className="terms__subsection">
                {sellerScript.plugin_details.map((text, i) => (
                  <ul className="terms__subsection_row">
                    <li>{text.title}</li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex w-screen justify-center bg-[#1a1a1a] py-4">
        <Footer />
      </div>
    </>
  );
}
