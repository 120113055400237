import { ActionType, Actions } from "./actions";
import { State } from "./types";

export function Reducer(state: State, action: Actions) {
	switch (action.type) {
		case ActionType.LOGIN:
			return {
				user: action.payload,
			};
		case ActionType.LOGOUT:
			return {
				user: null,
			};
		default:
			return state;
	}
}
