import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ALL_PAGES_PADDING_X } from "../../pages/MainIntro";

export default function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const screensize = window.screenY;
  console.log("screensize", screensize);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > screensize);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`fixed z-[100] flex h-[80px] w-full px-[10%] transition-all duration-300 px-[${ALL_PAGES_PADDING_X}] md:px-[15%] ${
        isScrolled ? "bg-white" : "bg-[#E8F3FF]"
      }`}
    >
      <div className="flex h-full w-full items-center justify-between">
        <Link to="/">
          <img
            src="/images/intro/logos/incento_logo.png"
            alt="Logo"
            className="w-[120px] md:w-[120px]"
          />
        </Link>
      </div>
      <div className="flex h-full w-fit items-center">
        <NavItem
          href="https://incento-app.notion.site/ec2f6ca310ef44ed993e123411694f8f"
          text="채용안내"
        />
        <NavItem href="https://dashboard.incento.kr/login/" text="로그인" />
        <NavItem
          href="https://incento-app.notion.site/Incento-Signup-Guide-13dbc7d6aa814dfdae3cdef2f886f127"
          text="회원가입"
        />
      </div>
    </nav>
  );
}

type NavItemProps = {
  href?: string;
  to?: string;
  text: string;
};

const NavItem: React.FC<NavItemProps> = ({ href, to, text }) => {
  return (
    <div className="flex w-[82px] justify-center rounded-md text-center text-[16px] text-[#222222] duration-300 hover:bg-white">
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ) : (
        to && <Link to={to}>{text}</Link>
      )}
    </div>
  );
};
