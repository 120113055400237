import { ALL_PAGES_PADDING_X } from "../../pages/MainIntro";
import { useInView } from "react-intersection-observer";

export default function WhyIncento() {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <div
      className={`intro-container px-[${ALL_PAGES_PADDING_X}] flex w-full flex-col items-center justify-center gap-10 bg-black py-[75px] text-center md:py-[168px]`}
    >
      <div className="mb-[50px] flex flex-col items-center justify-center">
        <div className="w-fit min-w-[68px] rounded-full bg-white p-[3px] text-center text-[#0F74ED]">
          WHY
        </div>
        <div className="my-4 text-[36px] font-semibold text-white">
          인센토, <br className="inline md:hidden" />왜 필요할까요?
        </div>
        <div className="text-[18px] text-[#9D9D9D]">
          비용이 높고 비효율적인 <br className="inline md:hidden" />
          이전의 마케팅 방식 대신, <br />
          효율적인 리퍼럴 마케팅에 투자해 <br className="inline md:hidden" />
          구매력 높은 핵심 고객을 만나보세요
        </div>
      </div>
      <style>
        {`
          @keyframes fadeInUp {
            0% {
              opacity: 0;
              transform: translateY(20px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .animate-fadeInUp {
            animation: fadeInUp 1s ease forwards;
          }
        `}
      </style>
      <div className="flex flex-col gap-[45px] md:flex-row md:gap-6" ref={ref}>
        {inView && (
          <div className="animate-fadeInUp mb-6 flex h-[300px] w-[300px] flex-col justify-center text-center">
            <div className="mb-[25px]">
              <div className="sub-title mb-[20px] text-[24px] font-bold text-white">
                <span>고객 획득 비용 절감</span>
              </div>
              <div className="text-[#9D9D9D]">
                <label>효율적인 리퍼럴 마케팅으로</label>
                <div>
                  <span>비용을 최대 </span>
                  <span className="font-semibold text-[#0F74ED]">
                    75%까지 절감
                  </span>
                  <span>하세요</span>
                </div>
              </div>
            </div>
            <div
              className="flex h-full w-[300px] items-center justify-center"
              style={{
                backgroundImage:
                  "url('/images/intro/section1(WHY)_img1(왼).svg')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
              }}
            />
          </div>
        )}
        {inView && (
          <div className="animate-fadeInUp mb-6 flex h-[300px] w-[300px] flex-col justify-center text-center duration-500">
            <div className="mb-[25px]">
              <div className="sub-title mb-[20px] text-[24px] font-bold text-white">
                <span>구매력 높은 고객 획득</span>
              </div>
              <div className="text-[#9D9D9D]">
                <div>
                  <span>리퍼럴을 통해 가입한 고객이</span>
                </div>
                <div>
                  <label>구매자로 전환될 확률이</label>
                  <span className="font-semibold text-[#0F74ED]">
                    {" "}
                    4배 더 높습니다
                  </span>
                </div>
              </div>
            </div>
            <div
              className="flex h-full w-[300px] items-center justify-center"
              style={{
                backgroundImage:
                  "url('/images/intro/section1(WHY)_img2(중앙).svg')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
              }}
            />
          </div>
        )}
        {inView && (
          <div className="animate-fadeInUp mb-6 flex h-[300px] w-[300px] flex-col justify-center text-center duration-1000">
            <div className="mb-[25px]">
              <div className="sub-title mb-[20px] text-[24px] font-bold text-white">
                <span>높은 LTV</span>
              </div>
              <div>
                <label className="text-[#9D9D9D]">LTV를</label>
                <span className="font-semibold text-[#0F74ED]">
                  {" "}
                  20% 증가시켜
                </span>
                <div className="text-[#9D9D9D]">
                  <span>장기적인 가치를 극대화 하세요 </span>
                </div>
              </div>
            </div>
            <div
              className="flex h-full w-[300px] items-center justify-center"
              style={{
                backgroundImage:
                  "url('/images/intro/section1(WHY)_img1(오른).svg')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
