import { ReactNode } from "react";
import Footer from "./Footer";
import Navigation from "./NavBar";

export default function PageLayout({ children }: { children: ReactNode }) {
  return (
    <>
      <Navigation />
      <div className="z-0">{children}</div>
      <div className="mx-auto flex w-screen justify-center bg-[#1a1a1a] py-4">
        <Footer />
      </div>
    </>
  );
}
