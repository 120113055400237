import { Shop, ShopWithRegistrationStatus } from "../lib/types";
import { useState, useEffect } from "react";
interface ShopsListComponentProps {
  shopsList: ShopWithRegistrationStatus[];
  registeredShops: number[];
  handleCheckboxChange: (shop: Shop) => void;
}

export default function ShopsListComponent({
  shopsList,
  registeredShops,
  handleCheckboxChange,
}: ShopsListComponentProps) {

  const [updatedRegisteredShops, setUpdatedRegisteredShops] = useState<number[]>([]);
  
  // In case main shop shop_no is null.
  useEffect(() => {
    const registeredShopsNoNull = registeredShops.map(shop => (shop === null ? 1 : shop));
    setUpdatedRegisteredShops(registeredShopsNoNull);
  }, [registeredShops]);

  return (
    <div id="shop-list-container">
      {/* no shops here */}
      {shopsList.length === 0 ? (
        <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50">
          <div className="text-center">
            <h2 className="mb-4 text-2xl font-semibold text-gray-800">
              만료된 액세스 코드입니다
            </h2>
            <p className="mb-8 text-gray-600">
              카페24에서 다시 관리 버튼을 클릭해 주세요.
            </p>
          </div>
        </div>
      ) : shopsList.length === 1 ? ( // only 1 shop
        <div className="container mx-auto min-w-[350px] rounded-2xl border border-blue-100 bg-white p-8 shadow-sm">
          <div className="mb-6">
            <p className="mb-2 text-lg font-semibold text-gray-800">
              카페24 ID
            </p>
            <div className="rounded-lg border border-gray-300 bg-gray-100 p-4 text-base text-[#a1a1a1] shadow-sm">
              {shopsList[0].shop_name}
            </div>
          </div>
          <div>
            <p className="mb-2 text-lg font-semibold text-gray-800">
              도메인 주소
            </p>
            <div className="rounded-lg border border-gray-300 bg-gray-100 p-4 text-base text-[#a1a1a1] shadow-sm">
              {shopsList[0].primary_domain}
            </div>
          </div>
        </div>
      ) : (
        // 2 shops
        <div className="container mx-auto min-w-[350px] rounded-2xl border border-gray-200 bg-white p-8 shadow-sm">
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b border-gray-300 bg-blue-50">
                <th className="p-5 text-left font-semibold text-gray-800">
                  선택
                </th>
                <th className="p-5 text-left font-semibold text-gray-800">
                  쇼핑몰 이름
                </th>
                <th className="p-5 text-left font-semibold text-gray-800">
                  도메인 주소
                </th>
              </tr>
            </thead>
            <tbody>
              {shopsList.map((shop, index) => {
              return (
                <tr
                  key={shop.shop_no}
                  className={`border-b border-gray-200 ${
                    updatedRegisteredShops.includes(shop.shop_no as number)
                      ? "cursor-not-allowed bg-[#aeaeae] opacity-50" // Change row color if shop is registered
                      : index % 2 === 0
                        ? "bg-gray-50"
                        : ""
                  } `}
                >
                  <td className="p-6">
                    <input
                      type="checkbox"
                      id={`checkbox-${shop.shop_no}`}
                      className={`h-4 w-4 ${
                        updatedRegisteredShops.includes(shop.shop_no as number)
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                      onChange={() => handleCheckboxChange(shop)}
                      disabled={updatedRegisteredShops.includes(
                        shop.shop_no as number,
                      )}
                    />
                  </td>
                  <td className="p-6 text-gray-800">{shop.shop_name}</td>
                  <td className="p-6 text-gray-800">{shop.primary_domain}</td>
                </tr>
              )
              })}
            </tbody>
          </table>
          <p className="mt-6 text-left text-base text-gray-600">
            * 등록할 샵을 선택하세요
          </p>
        </div>
      )}
    </div>
  );
}
