// utils.ts
export const getQueryParams = (param: any) => {
  const params = new URL(window.location.href).searchParams;
  return params.get(param);
};

export const getCookieValue = (cookieName: any) => {
  const cookieEntries = document.cookie.split("; ");
  for (let i = 0; i < cookieEntries.length; i++) {
    if (cookieEntries[i].includes(`${cookieName}=`)) {
      return cookieEntries[i].split("=")[1];
    }
  }
  return null;
};

export const setCookie = (
  name: string,
  value: string,
  domain: string,
  path: string = "/"
) => {
  document.cookie = `${name}=${value};domain=${domain};path=${path};`;
  // document.cookie = `${name}=${value};domain=${domain};`;
};
