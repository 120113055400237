import { ALL_PAGES_PADDING_X } from "../../pages/MainIntro";
import { useInView } from "react-intersection-observer";

export default function FeaturesIncento() {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const { ref: ref22, inView: inView22 } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <div
      className={`intro-container flex w-full flex-col items-center justify-center gap-10 pb-[75px] text-center px-[${ALL_PAGES_PADDING_X}] ] md:px-[15%]`}
    >
      <div className="text-container z-10 flex flex-col items-center justify-center pt-[60px] md:pt-[150px]">
        <div className="w-fit min-w-[60px] rounded-full border border-[#0F74ED] p-[3px] px-4 text-center text-[#0F74ED]">
          FEATURES
        </div>
        <div className="my-4 text-[36px] font-semibold text-[#222222]">
          인센토 <br className="inline md:hidden" />
          주요 기능 소개
        </div>
        <div className="text-[18px] text-[#636363]">
          단 5분 안에 효과적인 <br className="inline md:hidden" />
          리퍼럴 마케팅으로 <br className="inline md:hidden" />
          구매력 높은 신규 고객을 확보하고,
          <br />
          적극적으로 리퍼럴하는 <br className="inline md:hidden" />
          소중한 고객을 특별히 관리해보세요
        </div>
      </div>
      <style>
        {`
          @keyframes fadeInLeft {
            0% {
              opacity: 0;
              transform: translateX(-20px);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .animate-fadeInLeft {
            animation: fadeInLeft 500ms ease forwards;
          }

          @keyframes fadeInRight {
            0% {
              opacity: 0;
              transform: translateX(20px);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .animate-fadeInRight {
            animation: fadeInRight 500ms ease forwards;
          }
        `}
      </style>
      <div
        className="mb-[125px] flex w-full flex-col items-center justify-center md:gap-[75px]"
        ref={ref}
      >
        {/* Section 1 */}
        <div className="mb-[200px] flex h-full max-h-[251px] w-full max-w-[1200px] flex-col items-center py-10 text-start md:mb-[150px] md:flex-row">
          {inView && (
            <div
              style={{ animationDuration: "1000ms" }}
              className="animate-fadeInLeft mb-[45px] flex h-full w-full flex-col items-start justify-center leading-[1.25]"
            >
              <div>
                <div className="mb-4 flex items-center leading-[1.25]">
                  <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-[#0F74ED] text-white">
                    1
                  </div>
                  <div className="ml-2 text-[24px] font-bold text-[#222222]">
                    맞춤 리퍼럴 리워드 설정
                  </div>
                </div>
                <div className="pl-[45px] text-[18px] text-[#222222]">
                  추천인과 피추천인에게 지급되는 쿠폰 및 포인트의 <br />
                  지급 시점을 직접 커스텀할 수 있습니다
                </div>
              </div>
            </div>
          )}
          <div
            className="flex h-fit w-full items-center justify-center md:h-[251px]"
            style={{
              backgroundImage:
                "url('/images/intro/section3(Features)_img1.svg')",
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            <img
              src="/images/intro/section3(Features)_img1.svg"
              alt="feature1"
              className="md:hidden"
            />
          </div>
        </div>

        {/* Section 2 - Mobile */}
        <div
          className="mb-[200px] flex h-full max-h-[251px] w-full max-w-[1200px] flex-col items-center py-10 text-start md:mb-[150px] md:hidden"
          ref={ref2}
        >
          {inView2 && (
            <div
              style={{ animationDuration: "1000ms" }}
              className="animate-fadeInLeft mb-[45px] flex h-full w-full flex-col items-start leading-[1.25]"
            >
              <div>
                <div className="mb-4 flex items-center leading-[1.25]">
                  <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-[#0F74ED] text-white">
                    2
                  </div>
                  <div className="ml-2 text-[24px] font-bold text-[#222222]">
                    리퍼럴 위젯 설정
                  </div>
                </div>
                <div className="pl-[45px] text-[18px] text-[#222222]">
                  자사몰 테마에 맞춰 디자인을 커스텀할 수 있으며,
                  <br className="hidden md:inline" />
                  위젯의 위치도 직접 설정하여 활용할 수 있습니다
                </div>
              </div>
            </div>
          )}
          <div className="flex h-fit w-full items-center justify-center pt-[50px] md:h-[251px]">
            <img
              src="/images/intro/section3(Features)_img2.svg"
              alt="feature1"
              className="md:hidden"
            />
          </div>
        </div>

        {/* 2. 데스크탑 뷰 */}
        <div
          className="mb-[200px] hidden h-full max-h-[251px] w-full max-w-[1200px] flex-col items-center py-10 text-start md:mb-[150px] md:flex md:flex-row"
          ref={ref22}
        >
          <div
            className="flex h-fit w-full items-center justify-center md:h-[251px]"
            style={{
              backgroundImage:
                "url('/images/intro/section3(Features)_img2.svg')",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
              backgroundSize: "auto",
            }}
          />
          {inView22 && (
            <div
              style={{ animationDuration: "1000ms" }}
              className="animate-fadeInRight flex h-full w-full flex-col items-end pl-6 leading-[1.25]"
            >
              <div>
                <div className="leading-[1.25leading-[1.25 mb-4 flex items-center">
                  <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-[#0F74ED] text-white">
                    2
                  </div>
                  <div className="ml-2 text-[24px] font-bold text-[#222222]">
                    리퍼럴 위젯 설정
                  </div>
                </div>
                <div className="pl-[45px] text-[18px] text-[#222222]">
                  자사몰 테마에 맞춰 디자인을 커스텀할 수 있으며,
                  <br />
                  위젯의 위치도 직접 설정하여 활용할 수 있습니다
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Section 3 */}
        <div
          className="mb-[50px] flex h-full max-h-[251px] w-full max-w-[1200px] flex-col items-center py-10 text-start md:flex-row"
          ref={ref3}
        >
          {inView3 && (
            <div
              style={{ animationDuration: "1000ms" }}
              className="animate-fadeInLeft mb-[45px] flex h-full w-full flex-col items-start leading-[1.25]"
            >
              <div>
                <div className="mb-4 flex items-center">
                  <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-[#0F74ED] text-white">
                    3
                  </div>
                  <div className="ml-2 text-[24px] font-bold text-[#222222]">
                    VIP 고객 관리
                  </div>
                </div>
                <div className="pl-[45px] text-[18px] text-[#222222]">
                  이제까지 발견하지 못했던 소중한 VIP 리퍼럴 고객들을{" "}
                  <br className="hidden md:inline" />
                  찾아내어, <br className="inline md:hidden" />
                  특별 관리할 수 있습니다
                </div>
              </div>
            </div>
          )}
          <div
            className="flex h-fit w-full items-center justify-center md:h-[251px]"
            style={{
              backgroundImage:
                "url('/images/intro/section3(Features)_img3.svg')",
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            <img
              src="/images/intro/section3(Features)_img3.svg"
              alt="feature3"
              className="md:hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
