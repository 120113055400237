import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { ALL_PAGES_PADDING_X } from "../../pages/MainIntro";

export default function CustomerTestimonies() {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const [isCompanyA, setIsCompanyA] = useState(true);
  const [companyData, setCompanyData] = useState({
    referralCount: 5439,
    newUserCount: 1328,
    newPurchase: 1328,
    newSalesCost: 68310400,
  });

  useEffect(() => {
    isCompanyA
      ? setCompanyData({
          referralCount: 5439,
          newUserCount: 1328,
          newPurchase: 357,
          newSalesCost: 68310400,
        })
      : setCompanyData({
          referralCount: 1814,
          newUserCount: 797,
          newPurchase: 449,
          newSalesCost: 37528820,
        });
  }, [isCompanyA]);

  return (
    <div
      className={`intro-container flex w-full flex-col items-center justify-center gap-10 px-[${ALL_PAGES_PADDING_X}] pb-[75px] text-center md:px-[15%] md:pb-[168px]`}
    >
      <style>
        {`
          @keyframes fadeInLeft {
            0% {
              opacity: 0;
              transform: translateX(-20px);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .animate-fadeInLeft {
            animation: fadeInLeft 500ms ease forwards;
          }
        `}
      </style>
      <div className="mb-[50px] flex flex-col items-center justify-center">
        <div className="w-fit min-w-[60px] rounded-full border border-[#0F74ED] p-[3px] px-4 text-center text-[#0F74ED]">
          SUCCESS CASE
        </div>
        <div className="my-4 text-[36px] font-semibold">
          인센토 <br className="inline md:hidden" />
          사용 성공 사례
        </div>
        <div className="text-[18px] text-[#9D9D9D]">
          60일간 인센토를 사용하여 <br className="inline md:hidden" />
          이루어낸 성과를 확인해 보세요
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="mb-[80px] flex gap-[40px] text-2xl font-semibold">
          <div onClick={() => setIsCompanyA(true)}>
            <div
              className={`text-[12px] font-thin ${isCompanyA ? "text-black" : "text-[#636363]"} `}
            >
              솔루션 60일 차
            </div>
            <div
              className={`flex justify-between ${isCompanyA ? "border-b-2 border-black text-black" : "text-[#636363]"} cursor-pointer`}
            >
              <img
                className={`fill-current ${isCompanyA ? "text-black" : "text-[#636363]"}`}
                src="/images/intro/section4_icon_회사_black.svg"
                alt="icon"
              />
              <span
                className={`${isCompanyA ? "text-black" : "text-[#636363]"}`}
              >
                A사
              </span>
            </div>
          </div>

          <div onClick={() => setIsCompanyA(false)}>
            <div className="text-[12px] font-thin text-[#636363]">
              솔루션 60일 차
            </div>
            <div
              className={`flex justify-between ${isCompanyA ? "text-[#636363]" : "border-b-2 border-black text-black"} cursor-pointer`}
            >
              <img
                className={`fill-current ${isCompanyA ? "text-[#636363]" : "text-black"}`}
                src="/images/intro/section4_icon_회사_black.svg"
                alt="icon"
              />
              <span
                className={`${isCompanyA ? "text-[#636363]" : "text-black"}`}
              >
                B사
              </span>
            </div>
          </div>
        </div>
        <div
          className="flex w-full flex-col items-center justify-center gap-6 px-10 md:flex-row"
          ref={ref}
        >
          <div
            key={isCompanyA ? "A-referral" : "B-referral"}
            className="animate-fadeInLeft flex h-[166px] w-[288px] flex-col items-center justify-center rounded-2xl bg-[#DCEDFF] text-center"
          >
            <div className="mb-3 text-[20px] text-[#222222]">리퍼럴 횟수</div>
            <div className="text-[36px] font-semibold text-[#222222]">
              {inView && (
                <CountUp
                  start={0}
                  end={companyData.referralCount}
                  duration={1.0}
                />
              )}
              회
            </div>
          </div>
          <div
            key={isCompanyA ? "A-newUser" : "B-newUser"}
            className="animate-fadeInLeft flex h-[166px] w-[288px] flex-col items-center justify-center rounded-2xl bg-[#B1D7FF] text-center"
          >
            <div className="mb-3 text-[20px] text-[#222222]">신규 가입자</div>
            <div className="text-[36px] font-semibold text-[#222222]">
              {inView && (
                <CountUp
                  start={0}
                  end={companyData.newUserCount}
                  duration={1.0}
                />
              )}
              명
            </div>
          </div>
          <div
            key={isCompanyA ? "A-newPurchase" : "B-newPurchase"}
            className="animate-fadeInLeft flex h-[166px] w-[288px] flex-col items-center justify-center rounded-2xl bg-[#2990FF] text-center"
          >
            <div className="mb-3 text-[20px] text-white">신규 구매자</div>
            <div className="text-[36px] font-semibold text-white">
              {inView && (
                <CountUp
                  start={0}
                  end={companyData.newPurchase}
                  duration={1.0}
                />
              )}
              명
            </div>
          </div>
          <div
            key={isCompanyA ? "A-newSales" : "B-newSales"}
            className="animate-fadeInLeft flex h-[166px] w-[288px] flex-col items-center justify-center rounded-2xl bg-[#0F74ED] text-center"
          >
            <div className="mb-3 text-[20px] text-white">신규 매출</div>
            <div className="text-[36px] font-semibold text-white">
              {inView && (
                <CountUp
                  start={0}
                  end={companyData.newSalesCost}
                  duration={1.0}
                />
              )}
              원
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
