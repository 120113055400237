import { ALL_PAGES_PADDING_X } from "../../pages/MainIntro";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

export default function ValueIncento() {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <div
      className={`intro-container px-[${ALL_PAGES_PADDING_X}] flex w-full flex-col items-center justify-center bg-black pb-[75px] text-center md:px-[15%] md:pb-[168px]`}
    >
      <style>
        {`
          @keyframes fadeInLeft {
            0% {
              opacity: 0;
              transform: translateX(-20px);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .animate-fadeInLeft {
            animation: fadeInLeft 500ms ease forwards;
          }
        `}
      </style>
      <div className="mb-[75px] flex flex-col items-center justify-center md:mb-[126px]">
        <div className="w-fit min-w-[80px] rounded-full bg-white p-[3px] text-center text-[#0F74ED]">
          VALUE
        </div>
        <div className="my-4 text-[36px] font-semibold text-white">
          숫자로 증명하는 <br className="inline md:hidden" />
          인센토의 가치
        </div>
        <div className="text-[18px] text-[#9D9D9D]">
          리퍼럴 솔루션을 사용하여, <br />
          그동안 놓쳤던 구매력 높은 <br className="inline md:hidden" />
          신규 고객을 발굴해 <br />
          자사몰 매출을 자연스럽게 늘려보세요
        </div>
      </div>

      <div
        className="flex w-full flex-col items-center justify-center gap-6 md:flex-row"
        ref={ref}
      >
        <div className="animate-fadeInLeft flex h-[211px] w-full max-w-[367px] flex-col items-center justify-center rounded-md bg-white text-center md:w-[367px]">
          <div className="mb-2 text-[48px] font-bold text-[#0F74ED]">
            {inView && <CountUp start={0} end={200000} duration={2.5} />}
          </div>
          <div className="text-[24px] font-bold text-[#222222]">
            누적 리퍼럴 횟수
          </div>
        </div>
        <div className="animate-fadeInLeft flex h-[211px] w-full max-w-[367px] flex-col items-center justify-center rounded-md bg-white text-center md:w-[367px]">
          <div className="mb-2 text-[48px] font-bold text-[#0F74ED]">
            {inView && <CountUp start={0} end={50000} duration={2.5} />}
          </div>
          <div className="animate-fadeInLeft text-[24px] font-bold text-[#222222]">
            신규 고객
          </div>
        </div>
        <div className="animate-fadeInLeft flex h-[211px] w-full max-w-[367px] flex-col items-center justify-center rounded-md bg-white text-center md:w-[367px]">
          <div className="mb-2 text-[48px] font-bold text-[#0F74ED]">
            {inView && <CountUp start={0} end={75} duration={2.5} />}%
          </div>
          <div className="text-[24px] font-bold text-[#222222]">
            절감된 고객 획득 비용
          </div>
        </div>
      </div>
    </div>
  );
}
