/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import { Reducer } from "./reducer";
import { State } from "./types";
import INITIAL_STATE from "./state";
import { Actions } from "./actions";

export const Context = React.createContext<{
	state: State;
	dispatch: React.Dispatch<Actions>;
}>({ state: INITIAL_STATE, dispatch: () => undefined });

export const ContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = React.useReducer(Reducer, INITIAL_STATE);

	React.useEffect(() => {
		console.log(`Rendering useEffect inside context.tsx file`);
		localStorage.setItem("state", JSON.stringify(state));
	}, [state]);

	return (
		<Context.Provider
			value={{
				state,
				dispatch,
			}}
		>
			{children}
		</Context.Provider>
	);
};
