import { useEffect } from "react";
import Subscribe from "../components/intro/Subscribe";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import PageLayout from "../components/base/PageLayout";
import Intro from "../components/intro/Intro";
import WhyIncento from "../components/intro/WhyIncento";
import ValueIncento from "../components/intro/ValueIncento";
import FeaturesIncento from "../components/intro/FeaturesIncento";
import CustomerTestimonies from "../components/intro/CustomerTestimonies";
import ReviewIncento from "../components/intro/ReviewIncento";
import LogoSlide from "../components/intro/LogoSlide";

export const ALL_PAGES_PADDING_X = "10%";

export default function MainIntro() {
  // When users click 설치하기 on cafe24 store, they're redirected to this page which stores some cookeis.
  // The cookies will be used in the connect page
  useEffect(() => {
    const searchString = window.location.search;
    const queryString = window.location.search.substring(
      1,
      searchString.lastIndexOf("&"),
    );
    const searchParams = searchString.substring(1).split("&");
    var redirect = false;
    var redirect_link = "";
    for (var i = 0; i < searchParams.length; i++) {
      if (searchParams[i].includes("mall_id")) {
        var key, value;
        [key, value] = searchParams[i].split("=");
        document.cookie = "mall_id=" + value + ";domain=.incento.kr";
        redirect = true;
        redirect_link =
          "https://" +
          value +
          ".cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=" +
          process.env.REACT_APP_CAFE24_CLIENT_ID +
          "&redirect_uri=https://www.incento.kr/connect&scope=" +
          process.env.REACT_APP_CAFE24_SCOPES;
      } else if (searchParams[i].includes("shop_no")) {
        var key, value;
        [key, value] = searchParams[i].split("=");
        document.cookie = "shop_no=" + value + ";domain=.incento.kr";
      } else if (searchParams[i].includes("hmac")) {
        var key, value;
        [key, value] = searchParams[i].split("=");
        var sha256 = hmacSHA256(
          queryString,
          "" + process.env.REACT_APP_CAFE24_CLIENT_SECRET,
        );
        var digest = encodeURIComponent(Base64.stringify(sha256));
        if (value !== digest) {
          redirect = true;
          redirect_link = "https://www.incento.kr/cafe24issue?reason=hmac";
        }
      } else if (searchParams[i].includes("timestamp")) {
        var key, value;
        [key, value] = searchParams[i].split("=");
        var current = new Date().getTime() / 1000;
        var diffHour = (current - parseFloat(value)) / 3600;
        if (!(Math.abs(diffHour) < 2)) {
          redirect = true;
          redirect_link = "https://www.incento.kr/cafe24issue?reason=timestamp";
        }
      }
    }

    if (redirect) {
      window.location.href = redirect_link;
    }
  }, []);

  return (
    <PageLayout>
      <Intro />
      <LogoSlide />
      <WhyIncento />
      <ValueIncento />
      <FeaturesIncento />
      <CustomerTestimonies />
      <ReviewIncento />
      <Subscribe />
    </PageLayout>
  );
}
