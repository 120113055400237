import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { checkUsernameValidityV2 } from "../lib/api";
import validEmailIcon from "../Assets/images/101.svg";
import { RegistrationFormData } from "../lib/types";

interface RegistrationFormProps {
  onFormSubmit: (data: RegistrationFormData) => void;
}

export default function RegistrationForm({
  onFormSubmit,
}: RegistrationFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<RegistrationFormData>();

  const username = watch("username", "");
  const password = watch("password", "");
  const [usernameValid, setUsernameValid] = useState(false);

  const handleAgreeAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setValue("terms", isChecked);
    setValue("privacy", isChecked);
    setValue("marketing", isChecked);
  };

  const handleCheckUsername = async (username: string) => {
    setError("username", { message: "" });
    try {
      const isValid = await checkUsernameValidityV2(username);
      setUsernameValid(isValid);
      if (!isValid) {
        setError("username", { message: "이미 사용 중인 아이디입니다" });
        return false;
      }
      return true;
    } catch (error) {
      setError("root", { message: "something went wrong" });
      return false;
    }
  };

  const onSubmit: SubmitHandler<RegistrationFormData> = async (data) => {
    const isValidUsername = await handleCheckUsername(data.username);
    if (!isValidUsername) {
      console.log("isit?:", isValidUsername);
      console.log("error");
    } else {
      const formData = {
        ...data,
      };
      onFormSubmit(formData);
    }
  };

  return (
    <div className="min-w-96 w-full rounded-2xl border border-blue-100 bg-white p-20 shadow-sm">
      <form className="mx-auto max-w-sm" onSubmit={handleSubmit(onSubmit)}>
        {/* username */}
        <div className="relative mb-5">
          <div className="flex flex-row items-center justify-between">
            <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
              아이디
            </label>

            <button
              type="button"
              onClick={() => handleCheckUsername(username)}
              className="mb-2 block rounded-xl bg-blue-100 px-2 py-1 text-xs text-gray-900 dark:text-white"
            >
              중복 확인
            </button>
          </div>
          <input
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            {...register("username", {
              required: "필수",
            })}
          />
          {usernameValid && (
            <img
              src={validEmailIcon}
              className="absolute right-2 -mt-8"
              alt="valid email icon"
            />
          )}

          {errors.username && (
            <span className="mt-2 text-sm text-red-400 dark:text-gray-400">
              {errors.username.message}
            </span>
          )}
        </div>

        {/* email */}
        <div className="mb-5">
          <label
            htmlFor="email"
            className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
          >
            이메일
          </label>
          <input
            id="email"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-red-500 focus:ring-red-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-red-500 dark:focus:ring-red-500"
            {...register("email", {
              required: "필수",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "이메일 형식 오류입니다",
              },
            })}
          />
          {errors.email && (
            <span className="mt-2 text-sm text-red-400 dark:text-gray-400">
              {errors.email.message}
            </span>
          )}
        </div>

        {/* phoneNumber */}
        <div className="mb-5">
          <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
            휴대폰 번호
          </label>
          <input
            type="tel"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            {...register("phoneNumber", {
              required: "필수",
              pattern: {
                value: /^[0-9]{11}$/,
                message: "Improper phone number",
              },
            })}
          />
          {errors.phoneNumber && (
            <span className="mt-2 text-sm text-red-400 dark:text-gray-400">
              {errors.phoneNumber.message}
            </span>
          )}
        </div>

        {/* password */}
        <div className="mb-5">
          <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
            비밀번호
          </label>
          <input
            type="password"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            {...register("password", {
              required: "필수",
              minLength: {
                value: 6,
                message: "비밀번호는 최소 6자 이상이어야 합니다",
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/,
                message: "비밀번호는 대문자, 소문자, 숫자를 포함해야 합니다.",
              },
            })}
          />
          {errors.password && (
            <span className="mt-2 text-sm text-red-400 dark:text-gray-400">
              {errors.password.message}
            </span>
          )}
        </div>

        {/* confirmPassword */}
        <div className="mb-5">
          <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
            비밀번호 확인
          </label>
          <input
            type="password"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            {...register("confirmPassword", {
              required: "필수",
              validate: (value) =>
                value === password || "비밀번호가 일치하지 않습니다", // Add validation logic
            })}
          />
          {errors.confirmPassword && (
            <span className="mt-2 text-sm text-red-400 dark:text-gray-400">
              {errors.confirmPassword.message}
            </span>
          )}
        </div>
        {/* checkboxes */}
        <fieldset>
          <legend className="sr-only">Checkbox variants</legend>

          <div className="mb-4 flex items-center">
            <input
              id="agreeAll"
              type="checkbox"
              checked={watch("terms") && watch("privacy") && watch("marketing")}
              onChange={handleAgreeAllChange}
              className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-800"
            />
            <label
              htmlFor="agreeAll"
              className="ms-2 text-sm font-normal text-gray-900 dark:text-gray-300"
            >
              <span className="pl-1">전체 동의</span>
            </label>
          </div>

          <div className="mb-4 flex items-center">
            <input
              id="terms"
              type="checkbox"
              {...register("terms", { required: true })}
              className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-800"
            />
            <label
              htmlFor="terms"
              className="ms-2 text-sm font-normal text-gray-900 dark:text-gray-300"
            >
              <a
                href="https://www.incento.kr/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-1 hover:text-blue-400 hover:underline"
              >
                이용 약관 동의 (필수)
              </a>
            </label>
          </div>

          <div className="mb-4 flex items-center">
            <input
              id="privacy"
              type="checkbox"
              {...register("privacy", { required: true })}
              className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-800"
            />
            <label
              htmlFor="privacy"
              className="ms-2 text-sm font-normal text-gray-900 dark:text-gray-300"
            >
              <a
                href="https://www.incento.kr/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-1 hover:text-blue-400 hover:underline"
              >
                개인정보 처리 방침 동의 (필수)
              </a>
            </label>
          </div>

          <div className="mb-4 flex">
            <div className="flex h-5 items-center">
              <input
                id="marketing"
                type="checkbox"
                {...register("marketing")}
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-800"
              />
            </div>
            <div className="ms-2 text-sm">
              <label
                htmlFor="marketing"
                className="text-sm font-normal text-gray-900 dark:text-gray-300"
              >
                <span className="pl-1"> 마케팅 메세지 수신 동의 (선택)</span>
              </label>
            </div>
          </div>
        </fieldset>

        {/* register button */}
        <button
          className="w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:w-auto"
          type="submit"
          disabled={isSubmitting}
        >
          Register
        </button>
        {errors.root && (
          <span className="text-red-400">{errors.root.message}</span>
        )}
      </form>
    </div>
  );
}
