import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import IconAdd from "../../../Assets/images/add-user.svg";
import BackButton from "../../../Assets/images/back-svg.svg";
import "../../../styles/pages/login/login.css";

import LoadingSpin from "../../../components/features/LoadingSpin";
import ShopsListComponent from "../../../components/ShopsListComponent";
import RegistrationForm from "../../../components/RegistrationForm";
import MerchantInfoCard from "../../../components/MerchantInfoCard";

import { getQueryParams, getCookieValue, setCookie } from "../../../lib/utils";
import {
  ShopMerchant,
  ShopWithRegistrationStatus,
  DatabaseRegisteredShop,
  RegistrationFormData,
  Shop,
} from "../../../lib/types";
import {
  createMainShop,
  registerUser,
  loginMerchant,
  registerMerchantToShop,
  createMultiShop,
  getMerchantList,
  getRegisteredShopsList,
  linkExistingMerchantstoShop,
  loginMerchantWithoutPassword,
} from "../../../lib/api";

// import { newShops } from "../lib/constants";


export default function Login() {
  const [mallId, setMallId] = useState<string | null>(null); // Stores cafe24ID from cookies.
  const [shopsList, setShopsList] = useState<ShopWithRegistrationStatus[]>([]); // Shop list beloging to that cafe24ID
  const [selectedShops, setSelectedShops] = useState<
    ShopWithRegistrationStatus[]
  >([]); // Shops selected for registration
  const [registeredMerchants, setRegisteredMerchants] = useState<
    ShopMerchant[]
  >([]); //Merchants registered for incento service under this cafe24ID

  const [registeredShops, setRegisteredShops] = useState<
    DatabaseRegisteredShop[]
  >([]); // Shops under this cafe24ID registered to incento service.

  const [isAddingMerchant, setIsAddingMerchant] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const navigate = useNavigate();

  function extractShopNumbers(shops: DatabaseRegisteredShop[]): number[] {
    return shops.map((shop) => shop.shop_no);
  }

  // Very important. Both Old and New logic.
  // Registering main shop and fetching shop list as soon as the page loads.
  useEffect(() => {
    // Simulate setting search parameters
    // Only for development.
    // Delete before pushing. TODO.
    // document.cookie = "mall_id=bremall; path=/"; // This cookie is needed in cookies. It's set by the homepage so we should simulate it this way.
    // const url = new URL(window.location.href);
    // url.searchParams.set("code", accessCode);
    // window.history.pushState({}, "", url);

    // Get the URL search parameters
    const searchParams = window.location.search.substring(1).split("&");
    let code = null;
    // look for access code in search params
    for (var i = 0; i < searchParams.length; i++) {
      if (searchParams[i].includes("code")) {
        [, code] = searchParams[i].split("=");
      }
    }

    if (code) {
      let mall_id = null;
      const cookie_entries = document.cookie.split("; ");
      // look for mall_id (cafe24 id) in cookies.
      for (var j = 0; j < cookie_entries.length; j++) {
        if (cookie_entries[j].includes("mall_id")) {
          [, mall_id] = cookie_entries[j].split("=");
        }
      }

      if (mall_id) {
        setMallId(mall_id);
        const config = {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        const data = {
          mall_id: mall_id,
          code: code,
        };

          checkMerchants(mall_id);
          registerShopAndFetchShopList(data);
          checkRegisteredShops(mall_id);
        
      }
    }
  }, []);

  // New logic
  const handleCheckboxChange = (shop: Shop) => {
    setSelectedShops((prevSelected) => {
      const isSelected = prevSelected.some(
        (selectedShop) => selectedShop.shop_no === shop.shop_no,
      );
      if (isSelected) {
        return prevSelected.filter(
          (selectedShop) => selectedShop.shop_no !== shop.shop_no,
        );
      } else {
        return [...prevSelected, shop];
      }
    });
  };

  // new logic
  const registerShopsAndLinkExistingMerchants = async () => {
    if (!selectedShops || selectedShops.length === 0) {
      alert("등록하려면 최소한 하나의 샵을 선택하세요");
      return;
    }
    setLoading(true); // Show loading state

    const shopID = getCookieValue("shop_id");

    try {
      for (const shop of selectedShops) {
        if (
          shop.shop_no === 1 ||
          shop.shop_no === null ||
          shop.shop_no === ""
        ) {
          // Main shop handling: Link merchants to the main shop, do nothing else
          for (const merchant of registeredMerchants) {
            try {
              await linkExistingMerchantstoShop(merchant.id, shopID); // Link merchant to main shop
              console.log(`Linked ${merchant} to main shop: ${shopID}`);
            } catch (error) {
              console.error(
                `Error linking merchant ${merchant} to main shop ${shopID}:`,
                error,
              );
            }
          }
        } else {
          // Multishop handling: Create multishop and link merchants to multishop
          try {
            const response = await createMultiShop(
              shopID as string,
              shop.shop_no,
              shop,
            );
            const multishopID = response.data.multishop_id;

            // Now link all merchants to the created multishop
            for (const merchant of registeredMerchants) {
              try {
                await linkExistingMerchantstoShop(merchant.id, multishopID);
                // console.log(`Linked ${merchant} to multishop: ${multishopID}`);
              } catch (error) {
                console.error(
                  `Error linking merchant ${merchant} to multishop ${multishopID}:`,
                  error,
                );
              }
            }
          } catch (error) {
            console.error(
              `Error creating multishop for shop ${shop.shop_no}:`,
              error,
            );
          }
        }
      }
    } catch (error) {
      console.log("Error registering");
    } finally {
      setLoading(false);
      checkRegisteredShops(mallId as string);
      setSelectedShops([]);
      alert("샵 등록이 완료되었습니다");
    }
  };

  // new logic
  const registerMerchantAndLinkExistingShops = async (
    formData: RegistrationFormData,
  ) => {
    const sid = getQueryParams("shop_sid");
    const merchantId = getQueryParams("ref_id");

    const isMerchant = sid ? (merchantId ? false : true) : true; // what happened to this?
    setLoading(true);
    const registerResponse = await registerUser(formData, isMerchant, sid);
    if (!registerResponse.data) {
      console.log("Something went wrong with registering new merchant user");
      return;
    }
    console.log("User registered:", registerResponse.data);
    const NewMerchantUsername = registerResponse.data.username;

    for (const shop of registeredShops) {
      try {
        await linkExistingMerchantstoShop(
          NewMerchantUsername,
          shop.sid,
          "username",
        );
        console.log(`Linked ${NewMerchantUsername} to: ${shop}`);
      } catch (error) {
        console.error(
          `Error linking merchant ${NewMerchantUsername} to ${shop}:`,
          error,
        );
      }
    }

    setLoading(false);
    checkMerchants(mallId as string); //update merchants list with the added merchant.
    setIsAddingMerchant(false); //form is displayed using this!
  };

  // New logic
  const handleFormSubmit = (formData: RegistrationFormData) => {
    const sid = getQueryParams("shop_sid");
    const merchantId = getQueryParams("ref_id");

    const isMerchant = sid ? (merchantId ? false : true) : true; // what happened to this?

    const registerMerchant = async () => {
      if (!selectedShops || selectedShops.length === 0) {
        alert("등록하려면 최소한 하나의 샵을 선택하세요");
        return;
      }
      setLoading(true);
      try {
        const registerResponse = await registerUser(formData, isMerchant, sid);
        if (!registerResponse.data) {
          console.log("Something went wrong with registering merchant");
          return;
        }
        console.log("User registered:", registerResponse.data);

        const loginResponse = await loginMerchant(
          formData.username,
          formData.password,
        );
        if (!loginResponse.data) {
          console.log(
            "Something went wrong with the second request - Logging in merchant",
          );
          return;
        }

        const domain =
          process.env.NODE_ENV === "production" ? ".incento.kr" : "localhost";
        setCookie("access", loginResponse.data.access, domain);
        setCookie("refresh", loginResponse.data.refresh, domain);

        const accessToken = getCookieValue("access");
        const shopID = getCookieValue("shop_id");
        // This only handles main shop registration. The multishop one is not functional.

        if (shopID && accessToken) {
          for (const shop of selectedShops) {
            if (
              shop.shop_no !== 1 &&
              shop.shop_no !== null &&
              shop.shop_no !== ""
            ) {
              try {
                // create a multi shop and register a merchant for that multi shop.
                const response = await createMultiShop(
                  shopID,
                  shop.shop_no,
                  shop,
                  // accessToken
                );
                const multishopID = response.data.multishop_id;
                const shopRegisterResponse = await registerMerchantToShop(
                  multishopID,
                  accessToken,
                );
                console.log(
                  "Merchant registered to Multishop:",
                  shopRegisterResponse.data,
                );

                console.log(`Shop registered:`, response);
              } catch (error) {
                // Handle any errors during the API call
                console.error(`Error registering shop ${shop.shop_no}:`, error);
              }
            } else {
              // handles main shop
              const shopRegisterResponse = await registerMerchantToShop(
                shopID,
                accessToken,
              );
              console.log(
                "Merchant registered to Main Shop:",
                shopRegisterResponse.data,
              );
            }
          }

          // All API calls were successful, navigate to /select
          navigate("/select");
        } else {
          console.log("Missing shopID or access token.");
        }
      } catch (error) {
        console.error("Error while registering Merchant:", error);
      } finally {
        setLoading(false);
      }
    };

    registerMerchant();
  };

  // new logic
  const handleLoginMerchantWithoutPassword = async (username: string) => {
    setLoading(true);

    try {
      const loginResponse = await loginMerchantWithoutPassword(username);

      const domain =
        process.env.NODE_ENV === "production" ? ".incento.kr" : "localhost";
      setCookie("access", loginResponse.data.access, domain);
      setCookie("refresh", loginResponse.data.refresh, domain);

      const accessToken = getCookieValue("access");
      const shopID = getCookieValue("shop_id");

      if (shopID && accessToken) {
        // All API calls were successful, navigate to /select
        navigate("/select");
      } else {
        console.log("Missing shopID or access token.");
      }
    } catch (error) {
      console.error("Error while logging in Merchant without user:", error);
    } finally {
      setLoading(false);
    }
  };

  // inside the useeffect
  const registerShopAndFetchShopList = async (data: {
    mall_id: string;
    code: string;
  }) => {
    try {
      // register main shop in DB
      const response = await createMainShop(data);

      // store sid in cookie
      if (response && response.shop_id) {
        document.cookie = `shop_id=${response.shop_id};domain=${
          process.env.NODE_ENV === "production" ? ".incento.kr" : "localhost"
        }`;

        const ListofShops = response.cafe24_shops_list ?? [];
        setShopsList(ListofShops);

        // If there's only the main shop, then automatically add it to the list of selected shops.
        // If there's more than 1, then the user gets to choose which shops to register.
        if (ListofShops.length === 1) {
          const mainShop = ListofShops.find((shop) => shop.shop_no === 1);
          if (mainShop) {
            setSelectedShops([mainShop]);
          }
        }
        // TODO: uncomment this code block before pushing.

        // TODO: Delete this Before Deployement!!
        // setShopsList((prevShopsList) => [...prevShopsList, ...newShops]); // this is only for multishop simulation so delete before pushing.
      } else {
        throw new Error("Invalid response from createMainShop API");
      }
    } catch (error) {
      console.error("An error occurred while registering main shop:", error);
    } finally {
      setInitialLoading(false);
    }
  };
  // inside the useeffect
  const checkMerchants = async (mallId: string) => {
    try {
      const response = await getMerchantList(mallId);
      if (response) {
        const MerchantsList = response.data ?? [];
        setRegisteredMerchants(MerchantsList.merchants);
      }
    } catch (error) {
      console.error("Error while checking merchants info", error);
    }
  };
  // inside the useeffect
  const checkRegisteredShops = async (mallId: string) => {
    try {
      const response = await getRegisteredShopsList(mallId);
      if (response) {
        setRegisteredShops(response.data ?? []);
      }
    } catch (error) {
      console.error("Error while checking Registered Shops", error);
    }
  };

  // New logic
    return (
      <div className="relative min-h-screen bg-slate-50">
        {initialLoading && (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-white">
            <LoadingSpin />
          </div>
        )}
        {loading && (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <LoadingSpin />
          </div>
        )}
        <div className="flex min-h-screen items-center justify-center gap-48 bg-slate-50">
          <div className="flex flex-col gap-2">
            <ShopsListComponent
              shopsList={shopsList}
              registeredShops={extractShopNumbers(registeredShops)}
              handleCheckboxChange={handleCheckboxChange}
            />

            {shopsList.length > 1 && registeredMerchants.length !== 0 && (
              <button
                onClick={registerShopsAndLinkExistingMerchants}
                // className={`w-fit flex flex-row items-center gap-2 bg-blue-500 text-white text-sm font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 focus:outline-none transition`}
                className={`flex w-fit flex-row items-center gap-2 rounded-lg py-2 px-4 transition ${
                  selectedShops.length === 0
                    ? "cursor-not-allowed bg-gray-300 text-gray-500"
                    : "bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                }`}
                disabled={selectedShops.length === 0}
              >
                샵 추가
              </button>
            )}
          </div>
          {!isAddingMerchant && (
            <div className="">
              {shopsList.length !== 0 && registeredMerchants.length === 0 && (
                <div className="w-[450px]">
                  <RegistrationForm onFormSubmit={handleFormSubmit} />
                </div>
              )}
              {shopsList.length !== 0 && registeredMerchants.length !== 0 && (
                <div className="flex w-fit min-w-[350px] flex-col gap-5">
                  <MerchantInfoCard
                    merchants={registeredMerchants}
                    handleOnClick={(username: string) =>
                      handleLoginMerchantWithoutPassword(username)
                    }
                  />
                  {registeredMerchants.length < 5 && (
                    <button
                      onClick={() => setIsAddingMerchant(true)}
                      className="flex w-fit flex-row items-center gap-2 self-end rounded-lg bg-blue-500 py-2 px-4 text-sm font-semibold text-white transition hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      추가
                      <img src={IconAdd} alt="Add Icon" className="h-5 w-5" />
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
          {isAddingMerchant && (
            <div className="flex w-[500px] flex-row items-center justify-center gap-2">
              <RegistrationForm
                onFormSubmit={registerMerchantAndLinkExistingShops}
              />
              <button
                onClick={() => setIsAddingMerchant(false)}
                className="flex h-fit w-32 flex-row items-center gap-2 rounded-lg border border-blue-100 bg-blue-50 py-2 px-4 text-base font-semibold text-blue-500 transition hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-200"
              >
                이전
                <img src={BackButton} alt="Back" className="h-5 w-5" />
              </button>
            </div>
          )}
        </div>
      </div>
    );  
}
