import Connect from "./pages/auth/connect/Connect";
import Terms from "./pages/policies/Terms";
import Privacy from "./pages/policies/Privacy";
import ShopSelect from "./pages/ShopSelect";
import ForgotPassword from "./pages/auth/forgotpw/ForgotPassword";
import ResetPassword from "./pages/auth/forgotpw/ResetPassword";
import Inquiries from "./pages/contact/Inquiries";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga";
import MainIntro from "./pages/MainIntro";

const App = () => {
  const TRACKING_ID = "UA-254138922-1";

  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.location.pathname === "/login") {
      window.location.href = "https://dashboard.incento.kr/login/";
    }
  }, []);

  return (
    <>
      <CookiesProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainIntro />} />
            <Route path="*" element={<MainIntro />} />
            <Route path="connect" element={<Connect />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="select" element={<ShopSelect />} />
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="password/:token" element={<ResetPassword />} />
            <Route path="inquiries" element={<Inquiries />} />
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
    </>
  );
};
export default App;
