import React, { useState } from "react";
import imgLogo from "../../../Assets/images/incento_logo.png";

import "../../../styles/pages/forgot/forgot.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import PageLayout from "../../../components/base/PageLayout";

export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [after, setAfter] = useState(false);

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      submitID(e);
    }
  }

  return (
    <PageLayout>
      <div className="h-screen w-screen overflow-x-hidden bg-gradient-to-r from-blue-500 to-blue-200">
        {/* <div className="w-screen mx-auto pb-5">
        <NavBar
          navbarbgColour="bg-white"
          navbarColour={true}
          blackText="text-[#30313c]"
          whiteText="text-white"
        />
      </div> */}
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex w-full items-center justify-center">
              <Link to={"/"}>
                <img src={imgLogo} alt="Logo" />
              </Link>
            </div>

            {after ? (
              <div className="mt-10 w-full max-w-[23rem]">
                <label htmlFor="text">{`요청이 접수되었습니다. 해당 아이디랑 연결된 계정으로 이메일을 보내드렸습니다`}</label>
              </div>
            ) : (
              <div className="mt-10 w-full max-w-[23rem]">
                <label htmlFor="text">가입한 아이디를 입력해주세요</label>
                <div className="mb-6 mt-5">
                  <input
                    onKeyDown={handleKeyDown}
                    type="text"
                    name="username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    id="username"
                    className="block w-full rounded-lg border border-solid border-gray-300 bg-white px-3 py-2.5 text-base font-normal text-gray-800 transition ease-in-out focus:outline-none"
                    placeholder={"아이디를 입력해주세요"}
                  />
                </div>

                <button
                  className="text-md w-full rounded-md bg-blue-500 px-6 py-2.5 font-bold text-white shadow transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-800"
                  onClick={submitID}
                >
                  비밀번호 재설정하기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );

  function submitID(e: any) {
    e.preventDefault();
    if (!username) {
      alert("아이디를 입력해주세요");
      return;
    }

    let backend =
      process.env.REACT_APP_SERVER_BASE_PATH + "account/reset_password/";
    axios
      .post(backend, { username: username })
      .then((response) => {
        setAfter(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 404) {
          alert("아이디를 확인해주세요");
          return;
        }
        // for all other errors
        alert("인센토 팀으로 문의를 남겨주세요");
        navigate("/login");
      });
  }
}
