import React from "react";
import { ALL_PAGES_PADDING_X } from "../../pages/MainIntro";
import { Link } from "react-router-dom";

export default function Subscribe() {
  return (
    <div
      className={`intro-container flex flex-col justify-center p-10 md:flex-row md:gap-[20%] md:py-[136px] px-[${ALL_PAGES_PADDING_X}] md:px-[15%]`}
    >
      <div className="text-container relative z-10">
        <div className="mb-[16px] text-[36px] font-semibold leading-[1.3]">
          구매력 높은 신규 고객 획득과 관리,
          <br />
          <span className="text-[#235BF5]">성장</span>의 시작입니다!
        </div>
        <div className="text-[18px] leading-[1.5] text-[#636363]">
          효율적인 비용으로 <br className="inline md:hidden" />
          새로운 고객을 만나보세요
          <br />
          지금 바로 문의주세요!
        </div>
        <div className="flex w-full gap-[8px] pt-[56px]">
          <Link to="/inquiries">
            <button class="h-[54px] w-[163px] rounded-md bg-black px-2 py-3 text-white duration-500 hover:border-2 hover:border-black hover:bg-white hover:font-semibold hover:text-black md:px-10">
              <p class="text-center text-[14px] md:text-xl">문의하기</p>
            </button>
          </Link>
        </div>
      </div>
      <div className="img-container flex max-w-[422px] items-center justify-center md:max-w-[450px]">
        <img
          src="/images/intro/footerTopImage.png"
          alt="inquiry_alt"
          className="relative z-0"
        />
      </div>
    </div>
  );
}
