import { ShopMerchant } from "../lib/types";
import { Link } from "react-router-dom";
import HyperlinkButton from "../Assets/images/hyperlink_arrow.svg";

interface MerchantInfoCardProps {
  merchants: ShopMerchant[];
  handleOnClick: (username: string) => void;
}

export default function MerchantInfoCard({
  merchants,
  handleOnClick,
}: MerchantInfoCardProps) {
  return (
    <div className="flex flex-col gap-3">
      {merchants.map((merchant) => (
        <div
          key={merchant.id}
          className="transform rounded-xl border border-gray-200 bg-white p-6 shadow-lg transition-transform hover:scale-105"
        >
          <div className="mb-2 text-xl font-bold text-gray-900">
            {merchant.username}
          </div>
          <div className="mb-4 text-sm text-gray-600">{merchant.email}</div>

          <div className="flex gap-2">
            <button
              onClick={() => handleOnClick(merchant.username)}
              className="flex w-fit flex-row items-center gap-2 rounded-lg bg-green-500 py-2 px-4 text-sm font-semibold text-white transition hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
            >
              대시보드로 가기
              <img src={HyperlinkButton} alt="go" className="h-5 w-5" />
            </button>{" "}
          </div>
          <div className="mt-2 text-right">
            <Link to="/forgot">
              <p className="cursor-pointer text-sm text-blue-500 hover:underline focus:outline-none">
                비밀번호 변경
              </p>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}
