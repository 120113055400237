import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

export default function ReviewIncento() {
  const [activeIndex, setActiveIndex] = useState(0);

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <div
      className={`intro-container flex w-full flex-col items-center justify-center gap-10 bg-[#E8F3FF] px-[10%] py-[50px] text-center md:px-[20%] md:pt-[168px]`}
    >
      <div className="absolute z-0 mb-[250px] hidden h-[204px] w-full bg-[#0F74ED] md:block"></div>
      <div className="mb-[25px] flex flex-col items-center justify-center">
        <div className="z-0 w-fit min-w-[60px] rounded-full border border-[#0F74ED] bg-white p-[3px] px-4 text-center text-[#0F74ED]">
          REVIEW
        </div>
        <div className="my-4 text-[36px] font-semibold">실제 고객사 후기</div>
        <div className="text-[18px] text-[#9D9D9D]">
          인센토를 실제로 사용하고 계신 고객사들의 사용 후기입니다
        </div>
      </div>
      <div className="flex w-fit items-start justify-start" ref={ref}>
        {/* PC뷰 */}
        <div className="z-0 hidden h-[900px] w-[800px] pb-[150px] md:block">
          <Swiper
            pagination={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            style={{ backgroundColor: "transparent" }}
            slidesPerView={1}
          >
            <SwiperSlide
              style={{ backgroundColor: "transparent", zIndex: "0" }}
            >
              <div className="flex items-start justify-center gap-6">
                <div className="flex h-fit w-[380px] flex-col items-center justify-center rounded-lg bg-white p-[38px]">
                  <div className="m-2 flex h-[30px] w-[50%] items-center justify-center gap-2">
                    <div className="min-w-fit text-[24px] font-bold">
                      뷰앤디
                    </div>
                    <img
                      className="h-[18px] p-1"
                      src="/images/intro/section5(WHY)_logo_beaund.svg"
                      alt="뷰앤디"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-[16px] text-[#5A5D61]">
                      마케팅 총괄
                    </div>
                    <div className="mt-2 flex h-[20px]">
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                    </div>
                  </div>
                  <div className="">
                    <div className="mb-2 text-left text-[16px] text-[#636363]">
                      설치와 관리는 매우 간편합니다. 인센토와 계약하기 전에
                      리퍼럴 앱들을 많이 찾아봤는데,
                      <br />이 만큼
                      <span className="font-semibold text-[#235BF5]">
                        {" "}
                        커스터마이즈가 자유롭고
                      </span>
                      , 세심하게 <br />
                      대응해주는 곳은 없었습니다!
                    </div>
                    <div className="mb-2 text-left text-[16px] text-[#636363]">
                      매번 신경 써주시고, 필요에 따라 커스텀도 <br />
                      변경해주시니 연계약을 진행하게 되었습니다. <br />
                      항상 좋은 기능을 제공해주셔서 감사합니다.
                    </div>
                  </div>
                </div>
                <div className="flex h-fit w-[380px] flex-col items-center justify-center rounded-lg bg-white p-[38px]">
                  <div className="m-2 flex h-[30px] w-[50%] items-center justify-center">
                    <div className="min-w-fit text-[24px] font-bold">타가</div>
                    <img
                      className="h-[18px] p-1"
                      src="/images/intro/section5(WHY)_logo_taga.svg"
                      alt="타가"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-[16px] text-[#5A5D61]">
                      마케팅 총괄
                    </div>
                    <div className="mt-2 flex h-[20px]">
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 text-left text-[16px] text-[#636363]">
                      우리 브랜드는 아직 규모가 크지 않지만, 빅인, 데이터라이즈,
                      그루비, 채널톡 등 CRM 관련 <br />
                      솔루션을 모두 사용해 봤습니다. 하지만 비용 대비 효율을
                      내지 못했죠. 인센토는{" "}
                      <span className="font-semibold text-[#235BF5]">
                        기대 이상으로 높은 효율
                      </span>
                      을 내고 있습니다. 처음엔 ’친구 추천이 얼마나 도움이
                      될까?’라고 생각했지만, 중요한 건 어떤 고객에게, 어떤
                      타이밍에 추천을 권유하느냐였습니다. 이 부분에서 인센토의
                      큰 도움을 받았습니다.
                    </div>
                    <div className="mb-2 text-left text-[16px] text-[#636363]">
                      대부분의 마테크 업체들은 큰 비용을 지불하는 클라이언트만
                      신경 쓰는 경향이 있어 실제 <br />
                      사용이 어렵고, 요청에 대한 피드백도 성의가 없었는데,
                      인센토는 다릅니다.{" "}
                      <span className="font-semibold text-[#235BF5]">
                        빠른 피드백과 함께 고민해주는 자세
                      </span>
                      가 인센토의 큰 차별점
                      <br />
                      입니다. 매출을 손쉽게 보완하고 회원가입을 <br />
                      늘리고 싶은 커머스나, 개발 인력이 부족해
                      <br />
                      도움이 필요한 회사라면 인센토를 추천합니다. 분명 만족하실
                      겁니다.
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ backgroundColor: "transparent" }}>
              <div className="flex items-start justify-center gap-6">
                <div className="flex h-fit w-[380px] flex-col items-center justify-center rounded-lg bg-white p-[38px]">
                  <div className="m-2 flex h-[30px] w-[50%] items-center justify-center gap-2">
                    <div className="min-w-fit text-[24px] font-bold">
                      레시피박스
                    </div>
                    <img
                      className="h-[18px] p-1"
                      src="/images/intro/section5(WHY)_logo_recipebox.svg"
                      alt="레시피박스"
                    />
                  </div>
                  <div className="mb-4">
                    <div className="text-[16px] text-[#5A5D61]">CEO</div>
                    <div className="mt-2 flex h-[20px]">
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 text-left text-[16px] text-[#636363]">
                      초창기부터 사용해 왔는데 매우 만족하고 있습니다. 메타
                      광고는 효율이 안 나와서 짜증났는데, 리퍼럴을 통해 가입된
                      신규 회원의 획득 비용이 매우 낮습니다.
                    </div>
                    <div className="mb-2 text-left text-[16px] text-[#636363]">
                      또한 가입 후 전환된 고객의 구매 객단가도
                      <span className="font-semibold text-[#235BF5]">
                        평균보다 높아서 말도 안 되는 ROAS가 나오고 있습니다.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex h-fit w-[381px] flex-col items-center justify-center rounded-lg bg-white p-[38px]">
                  <div className="m-2 flex h-[30px] w-[50%] items-center justify-center gap-2">
                    <div className="min-w-fit text-[24px] font-bold">
                      풀라이트
                    </div>
                    <img
                      className="h-[18px] p-1"
                      src="/images/intro/section5(WHY)_logo_fullight.svg"
                      alt="풀라이트"
                    />
                  </div>
                  <div className="mb-2">
                    <div className="text-[16px] text-[#5A5D61]">
                      마케팅 총괄
                    </div>
                    <div className="mt-2 flex h-[20px]">
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                      <img src="/images/intro/hero_카페24star.svg" alt="star" />
                    </div>
                  </div>
                  <div>
                    <div className="text-left text-[16px] text-[#636363]">
                      응대가 굉장히 빠르고 좋으며, 자유롭게 커스터마이즈할 수
                      있어서 만족스럽습니다.
                      <br />
                      셋팅 후{" "}
                      <span className="font-semibold text-[#235BF5]">
                        적은 리소스로도 고객 모수를 확보할 수 있다는 점
                      </span>
                      이 가장 마음에 듭니다.
                      <br />
                      일반 광고로 고객을 유입시키는 것보다 훨씬 효율적입니다.
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* 모바일뷰 */}
        <div className="block w-full gap-[45px] md:hidden">
          <div className="mx-auto mb-6 flex h-fit w-full max-w-[380px] flex-col items-center justify-center rounded-lg bg-white p-6 sm:p-8">
            <div className="m-2 flex h-[30px] w-[50%] items-center justify-center gap-2">
              <div className="min-w-fit text-[24px] font-bold">뷰앤디</div>
              <img
                className="h-[18px]"
                src="/images/intro/section5(WHY)_logo_beaund.svg"
                alt="뷰앤디"
              />
            </div>
            <div className="mb-4">
              <div className="text-[16px] text-[#5A5D61]">마케팅 총괄</div>
              <div className="mt-2 flex h-[20px]">
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
              </div>
            </div>
            <div className="">
              <div className="mb-2 text-left text-[16px] text-[#636363]">
                설치와 관리는 매우 간편합니다.
                <br className="" />
                인센토와 계약하기 전에 리퍼럴 앱들을 많이 찾아봤는데,{" "}
                <br className="hidden md:inline" />이 만큼
                <span className="font-semibold text-[#235BF5]">
                  {" "}
                  커스터마이즈가 자유롭고
                </span>
                , 세심하게 <br className="hidden md:inline" />
                대응해주는 곳은 없었습니다!
              </div>
              <div className="mb-2 text-left text-[16px] text-[#636363]">
                매번 신경 써주시고, 필요에 따라 커스텀도{" "}
                <br className="hidden md:inline" />
                변경해주시니 연계약을 진행하게 되었습니다. <br />
                항상 좋은 기능을 제공해주셔서 감사합니다.
              </div>
            </div>
          </div>
          <div className="mx-auto mb-6 flex h-fit w-full max-w-[380px] flex-col items-center justify-center rounded-lg bg-white p-6 sm:p-8">
            <div className="m-2 flex h-[30px] w-[50%] items-center justify-center gap-2">
              <div className="min-w-fit text-[24px] font-bold">타가</div>
              <img
                className="h-[18px]"
                src="/images/intro/section5(WHY)_logo_taga.svg"
                alt="타가"
              />
            </div>
            <div className="mb-4">
              <div className="text-[16px] text-[#5A5D61]">마케팅 총괄</div>
              <div className="mt-2 flex h-[20px]">
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
              </div>
            </div>
            <div>
              <div className="mb-2 text-left text-[16px] text-[#636363]">
                우리 브랜드는 아직 규모가 크지 않지만, 빅인, 데이터라이즈,
                그루비, 채널톡 등 CRM 관련 <br className="hidden md:inline" />
                솔루션을 모두 사용해 봤습니다. 하지만 비용 대비 효율을 내지
                못했죠. <br className="inline md:hidden" />
                인센토는{" "}
                <span className="font-semibold text-[#235BF5]">
                  기대 이상으로 높은 효율
                </span>
                을 내고 있습니다. 처음엔 ’친구 추천이 얼마나 도움이 될까?’라고
                생각했지만, 중요한 건 어떤 고객에게, 어떤 타이밍에 추천을
                권유하느냐였습니다. 이 부분에서 인센토의 큰 도움을 받았습니다.
              </div>
              <div className="mb-2 text-left text-[16px] text-[#636363]">
                대부분의 마테크 업체들은 큰 비용을 지불하는 클라이언트만 신경
                쓰는 경향이 있어 실제 <br className="hidden md:inline" />
                사용이 어렵고, 요청에 대한 피드백도 성의가 없었는데, 인센토는
                다릅니다.{" "}
                <span className="font-semibold text-[#235BF5]">
                  빠른 피드백과 함께 고민해주는 자세
                </span>
                가 인센토의 큰 차별점
                <br className="hidden md:inline" />
                입니다. 매출을 손쉽게 보완하고 회원가입을{" "}
                <br className="hidden md:inline" />
                늘리고 싶은 커머스나, 개발 인력이 부족해{" "}
                <br className="hidden md:inline" />
                도움이 필요한 회사라면 인센토를 추천합니다. <br />
                분명 만족하실 겁니다.
              </div>
            </div>
          </div>
          <div className="mx-auto mb-6 flex h-fit w-full max-w-[380px] flex-col items-center justify-center rounded-lg bg-white p-6 sm:p-8">
            <div className="m-2 flex h-[30px] w-[50%] items-center justify-center gap-2">
              <div className="min-w-fit text-[24px] font-bold">레시피박스</div>
              <img
                className="h-[18px]"
                src="/images/intro/section5(WHY)_logo_recipebox.svg"
                alt="레시피박스"
              />
            </div>
            <div className="mb-4">
              <div className="text-[16px] text-[#5A5D61]">CEO</div>
              <div className="mt-2 flex h-[20px]">
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
              </div>
            </div>
            <div>
              <div className="mb-2 text-left text-[16px] text-[#636363]">
                초창기부터 사용해 왔는데 매우 만족하고 있습니다. 메타 광고는
                효율이 안 나와서 짜증났는데, 리퍼럴을 통해 가입된 신규 회원의
                획득 비용이 매우 낮습니다.
              </div>
              <div className="mb-2 text-left text-[16px] text-[#636363]">
                또한 가입 후 전환된 고객의 구매 객단가도
                <span className="font-semibold text-[#235BF5]">
                  평균보다 높아서 말도 안 되는 ROAS가 나오고 있습니다.
                </span>
              </div>
            </div>
          </div>
          <div className="mx-auto mb-6 flex h-fit w-full max-w-[380px] flex-col items-center justify-center rounded-lg bg-white p-6 sm:p-2">
            <div className="m-2 flex h-[30px] w-[50%] items-center justify-center gap-2">
              <div className="min-w-fit text-[24px] font-bold">풀라이트</div>
              <img
                className="h-[18px]"
                src="/images/intro/section5(WHY)_logo_fullight.svg"
                alt="풀라이트"
              />
            </div>
            <div className="mb-2">
              <div className="text-[16px] text-[#5A5D61]">마케팅 총괄</div>
              <div className="mt-2 flex h-[20px]">
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
                <img src="/images/intro/hero_카페24star.svg" alt="star" />
              </div>
            </div>
            <div>
              <div className="text-left text-[16px] text-[#636363]">
                응대가 굉장히 빠르고 좋으며, 자유롭게 커스터마이즈할 수 있어서
                만족스럽습니다.
                <br />
                셋팅 후{" "}
                <span className="font-semibold text-[#235BF5]">
                  적은 리소스로도 고객 모수를 확보할 수 있다는 점
                </span>
                이 가장 마음에 듭니다.
                <br />
                일반 광고로 고객을 유입시키는 것보다 훨씬 효율적입니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
