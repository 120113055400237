import axios from "axios";
import { RegistrationFormData, Shop } from "./types";

const apiUrl = process.env.REACT_APP_SERVER_BASE_PATH;

interface CreateShopResponseInterface {
  shop_id: string;
  new_register: boolean;
  cafe24_shops_list?: any[];
}

// TODO: Delete After Checking
export function checkUsernameValidity(
  e: React.MouseEvent<HTMLButtonElement>,
  username: string,
  setUsernameValid: (isValid: boolean) => void
) {
  if (username === "") {
    setUsernameValid(false);
  } else {
    axios
      .get(process.env.REACT_APP_SERVER_BASE_PATH + "account/check-username", {
        params: {
          username: username,
        },
      })
      .then((response) => {
        setUsernameValid(response.data.validFlag);
        if (!response.data.validFlag) {
          alert("이미 사용중인 아이디입니다.");
        }
        // console.log(response);
      })
      .catch((e) => {
        console.log(e);
        alert(e);
        // window.location.href = "/connect"
      });
  }
}

// verify username is unique.
export function checkUsernameValidityV2(username: string) {
  if (username === "") {
    return Promise.resolve(false);
  } else {
    return axios
      .get(process.env.REACT_APP_SERVER_BASE_PATH + "account/check-username", {
        params: {
          username: username,
        },
      })
      .then((response) => {
        return response.data.validFlag;
      })
      .catch((e) => {
        // console.log(e);
        alert(e);
        return false;
        // window.location.href = "/connect"
      });
  }
}

// TODO: DELETE AFTER CHECKING
export function loginUser(username: string, password: string) {
  const backend =
    process.env.REACT_APP_SERVER_BASE_PATH + "account/login-merchant/";
  axios
    .post(backend, { username, password })
    .then((response) => {
      document.cookie = `access=${response.data.access};domain=${
        process.env.NODE_ENV === "production" ? ".incento.kr" : "localhost"
      }`;
      document.cookie = `refresh=${response.data.refresh};domain=${
        process.env.NODE_ENV === "production" ? ".incento.kr" : "localhost"
      }`;
      return response.data;
      // navigate("/select");
    })
    .catch((err) => {
      if (err.response.status === 400) {
        alert("아이디랑 비밀번호를 확인해주세요");
      } else {
        console.error("An error occurred during login:", err);
      }
      throw err;
    });
}

// Creates main shop. Returns data of type CreateShopResponseInterface.
export function createMainShop(data: {
  mall_id: string;
  code: string;
}): Promise<CreateShopResponseInterface> {
  // this config object might not be needed.
  const config = {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const apiUrl = process.env.REACT_APP_SERVER_BASE_PATH;

  return axios
    .post(`${apiUrl}shop/createV2/`, data, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("An error occurred in in createMainShop API:", error);
      throw error;
    });
}

export const registerUser = async (
  formData: RegistrationFormData,
  isMerchant: boolean,
  sid: string | null
) => {
  const userRegisterEndpoint = `${apiUrl}users/`;
  const shop = isMerchant ? {} : { shop: sid };
  return axios.post(userRegisterEndpoint, {
    username: formData.username,
    password: formData.password,
    email: formData.email,
    number: formData.phoneNumber,
    is_superuser: false,
    is_staff: false,
    referral_link: "",
    is_merchant: isMerchant,
    is_shop_manager: !isMerchant,
    sns_flag: formData.marketing,
    ...shop,
  });
};

export const loginMerchant = async (username: string, password: string) => {
  const loginMerchantEndpoint = `${apiUrl}account/login-merchant/`;
  return axios.post(loginMerchantEndpoint, { username, password });
};

export const registerMerchantToShop = async (
  shopID: string,
  accessToken: string
) => {
  const registerMerchantToShopEndpoint = `${apiUrl}shop/register_merchant/`;
  const config = {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${accessToken}`, //jwt token for incentoBackend not cafe24
      "Content-Type": "application/json",
    },
  };
  const data = { shopId: shopID };
  return axios.post(registerMerchantToShopEndpoint, data, config);
};

export const createMultiShop = async (
  mainShopID: string,
  shopNo: number | string,
  shop: Shop
) => {
  const createMultiShopEndpoint = `${apiUrl}shop/createMultiShop/`;
  const data = { mainShopID, shopNo, shop };
  return axios.post(createMultiShopEndpoint, data);
};

export const getMerchantList = async (mallId: string) => {
  const getMerchantListEndpoint = `${apiUrl}shop/get_merchant_list/`;

  return axios.post(getMerchantListEndpoint, {
    mallId: mallId,
  });
};

export const getRegisteredShopsList = async (mallId: string) => {
  const getRegisteredShopsListEndpoint = `${apiUrl}shop/get_registered_shops_list/`;

  return axios.post(getRegisteredShopsListEndpoint, {
    mallId: mallId,
  });
};

export const linkExistingMerchantstoShop = async (
  merchant: string,
  shopId: string | null,
  merchant_type?: string
) => {
  const Endpoint = `${apiUrl}shop/link_merchant_to_shop/`;

  return axios.post(Endpoint, {
    shopId: shopId,
    merchant: merchant,
    merchant_type: merchant_type,
  });
};

export const loginMerchantWithoutPassword = async (username: string) => {
  const Endpoint = `${apiUrl}account/login-merchant-without-password/`;
  return axios.post(Endpoint, { username });
};
