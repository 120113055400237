import React, { useEffect, useState } from "react";
import "../styles/pages/select/select.css";

import Breadcrumbs from "../Assets/images/breadcrumbs.svg";
import axios from "axios";
import LoginNavbar from "../components/base/LoginNavbar";

interface merchantShop {
  name: string;
  sid: string;
  logoUrl: string;
}

export default function ShopSelect() {
  const [shops, setShops] = useState([]);

  useEffect(() => {
    var access;
    var refresh;
    var key;

    var cookie_entries = document.cookie.split("; ");
    for (var j = 0; j < cookie_entries.length; j++) {
      if (cookie_entries[j].includes("access=")) {
        [key, access] = cookie_entries[j].split("=");
      }
      if (cookie_entries[j].includes("refresh=")) {
        [key, refresh] = cookie_entries[j].split("=");
      }
    }
    if (!access) {
      window.location.href =
        process.env.NODE_ENV === "production"
          ? "https://www.incento.kr/login"
          : "http://localhost:3001/login";
    }

    let config = {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + access,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(
        process.env.REACT_APP_SERVER_BASE_PATH + "merchant-shop/list/",
        config,
      )
      .then((response) => {
        setShops(response.data);
      });
  }, []);

  function selectHandler(sid: string) {
    document.cookie = `shop=${sid};domain=${
      process.env.NODE_ENV === "production" ? ".incento.kr" : "localhost"
    };path=/`;
    window.location.href =
      process.env.NODE_ENV === "production"
        ? "https://dashboard.incento.kr"
        : "http://localhost:3000";
  }

  return (
    <div className="select__container" id="home">
      <LoginNavbar />
      <div className="select__box">
        <div className="select__title">
          <p className="select__title_main">내 채널 목록</p>
          <p className="select__title_description">
            대시보드로 이동할 샵 계정을 선택해주세요
          </p>
        </div>
        {shops.map((shop: merchantShop, i) => {
          return (
            <div
              className="select__shops"
              key={shop.sid}
              onClick={() => {
                selectHandler(shop.sid);
              }}
            >
              <div className="select__shop">
                <div className="select__shop_header">
                  <img
                    src={shop.logoUrl}
                    className="select__shop_image"
                    onError={(event) => {
                      (event.target as HTMLImageElement).src =
                        "https://referral-bucket-test.s3.ap-northeast-2.amazonaws.com/incento_square.png";
                    }}
                  />
                  <p className="select__shop_image_header">{shop.name}</p>
                </div>
                <img
                  src={Breadcrumbs}
                  alt="arrow-right"
                  className="select__shop_select"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
